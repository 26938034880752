<template>
  <div class="mz-layout">
    <a-layout :style="{ paddingTop: '64px' }">
      <MzHeader
        @showModule="showModule"
        @changeShow="changeShow"
        :menuIsShow="visible"
        @closeMenu="handleClose"
        @change="handleClick"
      />
      <!-- <MzFixedNav :modules="modules" @change="handleClick" /> -->
      <a-layout class="inside-layout" style="position: relative">
        <a-layout-sider
          :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }"
          :width="200"
          collapsedWidth="88px"
          :collapsed="isCollapse"
        >
          <div class="layout-aside">
            <MzAside @handleCollapse="handleCollapse" @selectMenuItem="selectMenuItem"></MzAside>
          </div>
        </a-layout-sider>
        <a-layout-content>
          <div class="layout-content" :style="{ marginLeft: isCollapse ? '88px' : '200px' }">
            <div
              class="tree-fold"
              v-if="$store.state.tenant.WhetherTree && $store.state.tenant.tree_all_type < 150"
              @click="showTree"
              @mouseenter="showTree"
            >
              <div>{{ checkBtnText }}</div>
            </div>
            <div class="layout-tree" :style="treeSideshow ? 'width:270px;' : 'width:0px'">
              <div class="zhezhao" v-if="treeSideshow" @click="hideTree"></div>
              <div
                class="tree-fold-bottom"
                v-if="$store.state.tenant.WhetherTree && $store.state.tenant.tree_all_type >= 150"
              >
                <div v-if="checkBtnShow" class="menu-check-btn" @click="checkBtn">
                  <span class="label"> 查询</span>
                </div>
                <div class="menu-fold-btn" @click="allFolding">
                  <span class="label">全部折叠</span>
                </div>

                <div class="close-btn" @click="hideTree">
                  <img
                    src="@/assets/close.png"
                    class="close-icon"
                    style="width: 20px; height: 20px"
                  />
                </div>
              </div>
            </div>

            <Breadcrumb :routes="showTagList" />

            <div class="mainContent" id="mainContent_0023">
              <keep-alive>
                <router-view v-if="$route.meta.keepAlive" ref="mainContent" class="router-view">
                </router-view>
              </keep-alive>
              <router-view
                v-if="!$route.meta.keepAlive"
                ref="mainContent"
                class="router-view"
              ></router-view>
            </div>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
import '../assets/icon/iconfont.css';
import MzHeader from './MzHeader';
import MzAside from './MzAside';
import AllProduct from './components/allProduct';
import { mapState } from 'vuex';
import {
  setCurrentMenuInStorage,
  getCurrentMenuInStorage,
  setRecentMenuList,
} from '@/storage/menu';
// import { setUserLog } from '@/api/BI-v2/index';
import MzFixedNav from '@/layouts/MzFixedNav';
import { getStoreBuildTodoNum, getStoreBuildNodeMonitorErrorCount } from '@/util/commonUtil';
let timer = null;
export default {
  data() {
    return {
      checkBtnShow: true,
      treeSideshow: false,
      visible: false,
      allShow: false,
      menuInfo: {},
      checkBtnText: '门店筛选',
    };
  },
  components: {
    MzFixedNav,
    MzAside,
    MzHeader,
    AllProduct,
  },

  watch: {
    $route: {
      handler(route) {
        let _this = this;
        let matchingUrl = route.meta.parentUrl || route.path;
        let menu = this.findMenuInfo('targetUrl', matchingUrl);

        // 设置访问tabList
        this.$store.commit('setTagList', this.$route);
        if (!menu.id) {
          //没有匹配到该菜单
          this.$store.commit('setCurrentMenu', getCurrentMenuInStorage());
          this.getStoreBuildTodoCountFunc();
          return;
        }
        this.getStoreBuildTodoCountFunc(menu);
        //判断是否还有父级菜单
        let parentIds = this.findAllParentIds(menu.parentId);
        this.$store.commit('setCurrentMenu', {
          selectedKeys: [menu.id],
          openKeys: parentIds,
          moduleId: menu.moduleId,
          menu: menu,
        });
        //本地存储
        setCurrentMenuInStorage({
          selectedKeys: [menu.id],
          openKeys: parentIds,
          moduleId: menu.moduleId,
          menu: menu,
        });

        this.getActive(this.menus);
        //是否显示筛选树的查询按钮
        this.$nextTick(function () {
          //页面加载完成后执行
          if (this.$refs.mainContent.hideCheckBtnText) {
            _this.checkBtnText = this.$refs.mainContent.hideCheckBtnText;
          } else {
            _this.checkBtnText = '门店筛选';
          }
          let url = window.location.href;
          if (url.indexOf('orderConfig/goodsManage') != -1) {
            _this.checkBtnText = '品类筛选';
          }
          if (
            typeof this.$refs.mainContent.noCheckbtn == 'function' ||
            this.$refs.mainContent.isCustomerBehavior ||
            this.$refs.mainContent.hideCheckBtn
          ) {
            this.checkBtnShow = false;
          } else {
            this.checkBtnShow = true;
          }
        });

        this.setLog(menu);
      },
      immediate: true,
    },
  },

  computed: {
    ...mapState({
      menus: (state) => state.menu.menus,
      modules: (state) => state.menu.modules,
      currentMenu: (state) => state.menu.currentMenu,
      tenant: (state) => state.tenant.tenant,
      isCollapse: (state) => state.menu.isCollapse,
      showTagList: (state) => {
        return state.menu.tagList.map((item) => {
          return {
            path: item.path,
            query: item.query || '',
            name: item?.meta?.pageName || '',
          };
        });
      },
      menuData: (state) => state.menu.menuData,
    }),
    activeStyle() {
      return this.allShow;
    },
    storeBuild_ModuleId() {
      let hostName = window.location.hostname;
      const testHosts = ['ykdtest01.ynzyq.net', 'ykdtest01.bjddlm.com'];
      let isPre = testHosts.some((host) => hostName.includes(host));
      return process.env.NODE_ENV == 'development' || isPre ? 1026 : 1039;
    },
  },

  methods: {
    formatMenuField(menu) {
      return {
        id: menu.id + '',
        name: menu.name + '',
        path: menu.path + '',
      };
    },
    selectMenuItem(menu) {
      let formatMenuItem = this.formatMenuField(menu);
      let itemMenu = JSON.parse(JSON.stringify(formatMenuItem));
      let menuId = this.menuData.menuTab.map((item) => item.id);
      if (menuId.indexOf(itemMenu.id) == -1) {
        this.menuData.menuTab.push(itemMenu);
        localStorage.setItem('menuTabB', JSON.stringify(this.menuData.menuTab));
      }
      this.menuData.menuIdB = itemMenu.id;
      localStorage.setItem('menuIdB', itemMenu.id);
    },

    getStoreBuildTodoCountFunc(menu = {}) {
      getStoreBuildTodoNum();
        // 查询节点监控异常数量
        getStoreBuildNodeMonitorErrorCount();
      // 如果当前系统模块是开店系统，切换一次左侧菜单就查询一次开店待办数量
      // let curModuleId = menu?.moduleId || this.currentMenu.moduleId;
      // if (curModuleId == this.storeBuild_ModuleId) {
      //   getStoreBuildTodoNum();
      //   // 查询节点监控异常数量
      //   getStoreBuildNodeMonitorErrorCount();
      // }
    },
    checkBtn() {
      // 订货单下发
      if (this.$refs.mainContent.$refs.orderCheckShow != undefined) {
        if (this.$refs.mainContent.$refs.orderCheckShow.$refs.manageOrderSearch) {
          this.$refs.mainContent.$refs.orderCheckShow.$refs.manageOrderSearch.getList();
        } else if (this.$refs.mainContent.$refs.orderCheckShow.$refs.coldOrderSearch) {
          this.$refs.mainContent.$refs.orderCheckShow.$refs.coldOrderSearch.getList();
        }
      }

      if (typeof this.$refs.mainContent.btnquery == 'function') {
        this.$refs.mainContent.btnquery();
      } else if (typeof this.$refs.mainContent.query == 'function') {
        this.$refs.mainContent.query();
      }
      this.hideTree();
    },
    // 折叠所有树结构
    allFolding() {
      if (this.$refs.mainContent.$refs.storeTree != undefined) {
        if (this.$refs.mainContent.$refs.storeTree.$refs.storeTree != undefined) {
          let treeRefs = this.$refs.mainContent.$refs.storeTree.$refs.storeTree.$refs.tree1;
          if (treeRefs) {
            this.$nextTick(() => {
              for (let i = 0; i < treeRefs.store._getAllNodes().length; i++) {
                treeRefs.store._getAllNodes()[i].expanded = false;
              }
            });
          }
        }
        if (this.$refs.mainContent.$refs.storeTree.$refs.tree1 != undefined) {
          let treeRefs = this.$refs.mainContent.$refs.storeTree.$refs.tree1;
          if (treeRefs) {
            this.$nextTick(() => {
              for (let i = 0; i < treeRefs.store._getAllNodes().length; i++) {
                treeRefs.store._getAllNodes()[i].expanded = false;
              }
            });
          }
        }
        if (this.$refs.mainContent.$refs.storeTree.$refs.commodityTree != undefined) {
          let treeRefs = this.$refs.mainContent.$refs.storeTree.$refs.tree1;
          if (treeRefs) {
            this.$nextTick(() => {
              for (let i = 0; i < treeRefs.store._getAllNodes().length; i++) {
                treeRefs.store._getAllNodes()[i].expanded = false;
              }
            });
          }
        }
        if (this.$refs.mainContent.$refs.storeTree.$refs.commodityTree != undefined) {
          console.log('1223------');
          let treeRefs =
            this.$refs.mainContent.$refs.storeTree.$refs.commodityTree.$refs.commodityTree;
          if (treeRefs) {
            this.$nextTick(() => {
              for (let i = 0; i < treeRefs.store._getAllNodes().length; i++) {
                treeRefs.store._getAllNodes()[i].expanded = false;
              }
            });
          }
        }
      }
      if (this.$refs.mainContent.$refs.commodityTree != undefined) {
        if (this.$refs.mainContent.$refs.commodityTree.$refs.commodityTree != undefined) {
          let commodityTreeRefs3 = this.$refs.mainContent.$refs.commodityTree.$refs.commodityTree;
          if (commodityTreeRefs3) {
            this.$nextTick(() => {
              for (let i = 0; i < commodityTreeRefs3.store._getAllNodes().length; i++) {
                commodityTreeRefs3.store._getAllNodes()[i].expanded = false;
              }
            });
          }
          if (
            this.$refs.mainContent.$refs.commodityTree.$refs.commodityTree.$refs.tree2 != undefined
          ) {
            let commodityTreeRefs0 =
              this.$refs.mainContent.$refs.commodityTree.$refs.commodityTree.$refs.tree2;
            if (commodityTreeRefs0) {
              this.$nextTick(() => {
                for (let i = 0; i < commodityTreeRefs0.store._getAllNodes().length; i++) {
                  commodityTreeRefs0.store._getAllNodes()[i].expanded = false;
                }
              });
            }
          }
          if (
            this.$refs.mainContent.$refs.commodityTree.$refs.commodityTree.$refs.tree3 != undefined
          ) {
            let commodityTreeRefs1 =
              this.$refs.mainContent.$refs.commodityTree.$refs.commodityTree.$refs.tree5;
            if (commodityTreeRefs1) {
              this.$nextTick(() => {
                for (let i = 0; i < commodityTreeRefs1.store._getAllNodes().length; i++) {
                  commodityTreeRefs1.store._getAllNodes()[i].expanded = false;
                }
              });
            }
          }
          if (
            this.$refs.mainContent.$refs.commodityTree.$refs.commodityTree.$refs.tree4 != undefined
          ) {
            let commodityTreeRefs4 =
              this.$refs.mainContent.$refs.commodityTree.$refs.commodityTree.$refs.tree5;
            if (commodityTreeRefs4) {
              // if (this.$refs.mainContent.$refs.commodityTree.$refs.commodityTree.$refs.tree5) {
              this.$nextTick(() => {
                for (let i = 0; i < commodityTreeRefs4.store._getAllNodes().length; i++) {
                  commodityTreeRefs4.store._getAllNodes()[i].expanded = false;
                }
              });
            }
          }
          if (
            this.$refs.mainContent.$refs.commodityTree.$refs.commodityTree.$refs.tree5 != undefined
          ) {
            let commodityTreeRefs5 =
              this.$refs.mainContent.$refs.commodityTree.$refs.commodityTree.$refs.tree5;
            if (commodityTreeRefs5) {
              // if (this.$refs.mainContent.$refs.commodityTree.$refs.commodityTree.$refs.tree5) {
              this.$nextTick(() => {
                for (let i = 0; i < commodityTreeRefs5.store._getAllNodes().length; i++) {
                  commodityTreeRefs5.store._getAllNodes()[i].expanded = false;
                }
              });
            }
          }
        }
        if (this.$refs.mainContent.$refs.commodityTree.$refs.storeTree != undefined) {
          if (this.$refs.mainContent.$refs.commodityTree.$refs.storeTree.$refs.tree1 != undefined) {
            let commodityTreeRefs2 =
              this.$refs.mainContent.$refs.commodityTree.$refs.storeTree.$refs.tree1;
            if (commodityTreeRefs2) {
              this.$nextTick(() => {
                for (let i = 0; i < commodityTreeRefs2.store._getAllNodes().length; i++) {
                  commodityTreeRefs2.store._getAllNodes()[i].expanded = false;
                }
              });
            }
          }
        }
      }
    },
    hideTree() {
      this.$store.commit('TREE_ALL', 0);
      this.treeSideshow = false;
    },
    showTree() {
      this.$store.commit('TREE_ALL', 230);
      this.treeSideshow = true;
    },
    changeShow() {
      this.visible = !this.visible;
      this.allShow = false;
    },
    showModule() {
      this.visible = true;
    },

    handleClose(data) {
      this.visible = false;
      this.allShow = false;
    },
    handleCollapse(data) {
      console.log('🚀 ~ handleCollapse ~ data:', data);
      this.$store.commit('setIsCollapse', data);
    },
    getActive(data) {
      Object.keys(data).forEach((key) => {
        data[key].forEach((item) => {
          item.isActive = false;
          if (this.currentMenu.openKeys.find((id) => id === item.id)) {
            item.isActive = true;
          }
        });
      });
    },
    handleMenu(data) {
      let newArr = [];
      let name = '';

      Object.keys(data).forEach((key) => {
        const temp = this.modules.find((item) => item.id == key);
        const res = this.formatMenu(data[key]);
        if (temp) {
          newArr.push({
            name: temp.moduleName,
            childTargetUrl: res,
            menuList: data[key],
            id: key,
          });
        }
      });

      this.menuInfo = newArr;
    },
    formatMenu(data) {
      const res = data.map((item, index) => {
        if (item.children && item.children.length) {
          const res = this.formatMenu(item.children);
          if (!item.parentId) {
            item.copyTargetUrl = res;
            if (!res) {
              item.isDisabled = true;
            }
          }
          return res;
        } else {
          if (!item.parentId && !item.targetUrl.trim()) {
            item.isDisabled = true;
          }
          if (item.isAuthed) {
            return item.targetUrl.trim();
          }
        }
      });
      return res.find((url) => url);
    },
    handleClick(data) {
      if (data.status != 1) return;
      const temp = this.menuInfo.find((item) => item.id == data.id);
      this.$router.push({
        path: temp.childTargetUrl,
      });
      this.handleClose();
    },
    clickProd() {
      setTimeout(() => {
        const res = JSON.parse(localStorage.getItem('currentMenu'));
        setRecentMenuList(res.menu);
      }, 100);
    },

    showAll() {
      timer = setTimeout(() => {
        this.allShow = true;
      }, 300);
    },
    handleMouseOver() {
      clearTimeout(timer);
    },
    changeAllShow() {
      // clearTimeout(timer)
      this.allShow = !this.allShow;
    },

    //查找菜单中对应字段的对应项
    findMenuInfo(key, value, source = this.menus, childrenKey = 'children') {
      let reslute = {};
      const handleFind = (menuItem, modules) => {
        menuItem.find((item) => {
          if (item[key] == value) {
            reslute = item;
          } else if (item[childrenKey]) {
            handleFind(item[childrenKey], modules);
          }
        });
      };

      for (let modules in source) {
        let menuItem = source[modules];
        handleFind(menuItem, modules);
      }
      return reslute;
    },

    //查找所有父级id
    findAllParentIds(parentId) {
      let parentIds = [];

      const handleFind = (parentId) => {
        let parent = this.findMenuInfo('id', parentId);
        if (parent.parentId != 0) {
          parentIds.push(parent.parentId);
          handleFind(parent.parentId);
        }
      };

      if (parentId != 0) {
        parentIds.push(parentId);
        handleFind(parentId);
      }
      return parentIds;
    },
    setLog(menu) {
      // setUserLog({
      //   type: 'PAGE',
      //   des: menu.menuName,
      //   router: menu.targetUrl,
      // }).then((res) => {
      //   console.log('res===>', res);
      // });
    },
  },
  onshow() {
    console.log(this.$refs.mainContent.isCustomerBehavior, '-------------onshow');
    if (this.$refs.mainContent.isCustomerBehavior) {
      this.checkBtnShow = false;
    } else {
      this.checkBtnShow = true;
    }
  },
  mounted() {
    let url = window.location.href;
    if (url.indexOf('orderConfig/goodsManage') != -1) {
      this.checkBtnText = '品类筛选';
    }
    this.hideTree();
    this.allFolding(); //折叠所有树
    // this.$bus.$on('collapse-aside', this.handleCollapse);
    this.handleMenu(this.menus);

    //是否显示筛选树的查询按钮
    if (
      typeof this.$refs.mainContent.noCheckbtn == 'function' ||
      this.$refs.mainContent.isCustomerBehavior ||
      this.$refs.mainContent.hideCheckBtn
    ) {
      this.checkBtnShow = false;
    } else {
      this.checkBtnShow = true;
    }

    // const tenentArr =  ['yjxm']
    // if(tenentArr.includes(this.tenant.code)) {
    //   const temp = this.modules.filter(item => item.moduleCode !== '665da8a895e3483baaa70f4e6f9abcc1')
    //   console.log('temp===>', temp);

    //   this.$store.commit('setModules', temp)
    // }
  },
};
</script>

<style scoped lang="scss">
.ant-layout-sider {
  z-index: 99;
}
.layout-aside {
  height: 100%;
  display: flex;
  // 白色背景
  background-color: var(--page-background);
}

.zhezhao {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100vw - 275px);
  height: 100vh;
  // background: pink;
  z-index: 999;
}
.layout-content {
  padding-left: 16px;
  padding-right: 16px;
  height: calc(100vh - 64px);
  scroll-behavior: smooth;
  overflow: auto;
  background: #e9ecf2;
  display: flex;
  flex-direction: column;
  .router-view {
    flex: auto;
    height: 100%;
  }
  .tree-fold {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    // left: 208px;
    right: 0;
    top: calc(50vh - 108px);
    padding: 10px 12px;
    width: 35px;
    text-align: center;
    border-radius: 8px;
    color: #fff;
    background: #ff6b00;
    box-shadow: 4px 0 19px 2px rgba($color: #666, $alpha: 0.2);
    z-index: 99;
    box-sizing: content-box;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s;
    user-select: none;

    .icon-right {
      margin-left: 4px;
      font-size: 10px;
    }
  }
  .close-btn {
    box-shadow: -7px 0px 19px 0px rgba(0, 0, 0, 0.2);
    background: #fff;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 8px 0 0 8px;
    position: fixed;
    top: 105px;
    right: 229px;
    z-index: 999;
    cursor: pointer;
  }

  .tree-fold-bottom {
    box-shadow: -7px 0px 19px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 0px;
    // left: 218px;
    right: 0;
    padding: 0 16px;
    width: 230px;
    height: 52px;
    line-height: 50px;
    background: white;
    z-index: 11;
    border: 1px solid #edebe9;
    transition: all 0.2s;
    .label {
      font-size: 14px;
      // margin-left: 8px;
    }
    .menu-check-btn {
      width: 60px;
      height: 32px;
      line-height: 32px;
      color: #fff;
      background: #ff6b00;
      border-radius: 4px;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      cursor: pointer;
    }
    .menu-fold-btn {
      width: 88px;
      height: 32px;
      line-height: 32px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }
  }
}

.active-style {
  color: #2a69f1;
  background: #ecf8ff;
  .all {
    color: #2a69f1;
  }
}
</style>

<style lang="scss">
.el-message-box__wrapper {
  z-index: 9999999991 !important;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: rgba($color: #666, $alpha: 0.2);
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba($color: #fff, $alpha: 0.2);
}

/* @import '@/pages/customerOperation/css/customerOperation'; */
$asideWidth: 180px;
$headHeight: 68px;
$bodySideMargin: 20px;
$bodySideWidth: 230px;
.header-mz {
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu .el-submenu__title {
    height: 41px;
    line-height: 41px;
    border: none;
    color: #fff;
    background: #ff6b00;
  }
  .el-menu .el-submenu__title:hover {
    color: #fff;
    background: #ff6b00;
  }
  .el-submenu__title i {
    color: #fff;
  }
  .el-menu--horizontal > .el-submenu:focus .el-submenu__title,
  .el-menu--horizontal > .el-submenu:hover .el-submenu__title {
    color: #fff;
  }
  .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
    border-bottom: none;
    color: #fff;
  }
}

.el-submenu__title {
  padding-left: 15px !important;
}

.el-menu--horizontal .el-menu .el-menu-item {
  height: 65px;
}

.menu-item {
  display: flex;
  height: 65px;
  align-items: center;
  color: #444444;

  img {
    width: 28px;
    height: 22px;
    margin: 0 15px 0 0;
  }
  div {
    .text-head {
      margin: 6px 0 6px;
      padding: 0;
      height: 14px;
      line-height: 14px;
      font-size: 14px;
    }
    span {
      font-size: 12px;
    }
  }
}

.el-menu--popup {
  width: 317px;
  padding: 0 20px 20px;
  height: 400px;
  overflow-y: scroll;
}
.el-dropdown-menu {
  top: 36px !important;
  width: 317px;
  padding: 0 20px 20px;
  height: 400px;
  overflow-y: scroll;
}

.el-dialog__wrapper {
  overflow: hidden;
}
.drawer1-setup {
  top: 48px;

  .el-drawer {
    background: rgba(250, 249, 248, 1);
  }

  .el-drawer__header {
    margin-bottom: 20px;
    span {
      font-size: 20px;
      color: #333333;
      font-weight: bold;
    }
  }
}
.drawer2-setup {
  top: 48px;
  .el-drawer {
    background: rgba(250, 249, 248, 1);
  }

  .el-drawer__header {
    margin-bottom: 20px;
    span {
      font-size: 20px;
      color: #333333;
      font-weight: bold;
    }
  }
}

.drawer-setup {
  .el-drawer__header {
    display: none;
  }
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.account-tenant ul::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
  visibility: hidden;
}

/*定义滚动条轨道 内阴影+圆角*/
.account-tenant ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
.account-tenant ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.el-submenu {
  .is-active {
  }
}
.el-menu-vertical-demo {
  min-height: calc(100vh - 93px);
}
.el-menu {
  background: #f3f2f1;
  color: #444444;

  .el-submenu {
    .is-active {
      color: rgba(0, 78, 140, 1) !important;
      background: rgba(194, 220, 240, 1) !important;
      font-weight: bold !important;

      .mz-icon {
        display: none;
      }
      .mz-icon-action {
        display: initial;
      }
    }
  }
  .el-menu-item.is-active {
    color: rgba(0, 78, 140, 1) !important;
    background: rgba(194, 220, 240, 1) !important;
    font-weight: bold !important;

    .mz-icon {
      display: none;
    }
    .mz-icon-action {
      display: initial;
    }
  }

  .el-menu-item:hover {
    color: rgba(0, 0, 0, 1);
    background: rgba(228, 228, 228, 0.3);
  }
}

.el-menu--collapse {
  width: 49px;
  .sub_text {
    display: none;
  }

  .el-icon-arrow-right {
    display: none;
  }

  .mz-icon-action {
    margin: 0;
  }
  .mz-icon {
    margin: 0;
  }
}

.el-menu-item {
  padding: 0 15px;
  .el-tooltip {
    padding: 0 !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}

a {
  text-decoration: none;
  color: #333333;
}

.filter {
  // margin: 0 0 15px;
  .el-input__inner {
    border: none;
    background: #fff;
    color: #c0c0c0;
    font-size: 14px;
    width: 200px;
    margin: 0 15px;
    height: 30px;
  }
  .el-input__prefix {
    left: 15px;
  }
  .el-input__suffix {
    right: 15px;
  }
  .el-input__icon {
    line-height: 30px;
  }
}

.el-tree-node__expand-icon {
  color: #333333;
}

.el-tabs__active-bar {
  height: 4px;
  background-color: #ff6b00;
}

.el-tabs__item {
  font-size: 14px;
  height: 56px;
  line-height: 56px;
  color: #333333 !important;
}
.el-tabs__nav {
  .is-active {
    font-weight: bold;
  }
}

.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #ff6b00;
  border-color: #ff6b00;
}

.section {
  .el-tabs__item.is-active {
    color: #ff6b00;
  }

  .el-tabs__active-bar {
    background: #ff6b00;
  }
  .el-tabs__item:hover {
    color: #ff6b00;
  }
}
.layout-tree {
  width: 0;
  height: calc(100vh - 96px);
  position: absolute;
  top: 48px;
  right: 0px;
  // background: pink;
}
.app-body-side {
  .el-tabs__item.is-active {
    color: #ff6b00;
  }

  .el-tabs__active-bar {
    background: #ff6b00;
  }
  .el-tabs__item:hover {
    color: #ff6b00;
  }
}

.el-tabs__nav-wrap {
  padding: 0 15px;
}
.el-tabs__nav-wrap::after {
  height: 1px;
}

.data_money_top {
  h3 {
    padding: 11px 0 10px 5px;
    margin: 0;
    color: #333333;
    font-weight: 600;
    font-size: 18px;
  }
}

//看板区域
.kanban-district {
  min-height: 215px;
  background: #ffffff;
  border: 1px solid #edebe9;
}

//概览区域
.overview-area {
  background: #ffffff;
  border-radius: 8px;
  padding-top: 0.5px;
  // border: 1px solid #edebe9;
}

// 看板部分样式

.font_grey {
  color: #424242;
  // text-align: center;
  span {
    // font-size: 14px;
    // color: #ff6b00;
    // font-weight: 600;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
  }
}
.font_weight {
  // font-weight: bold;
  // font-size: 25px;
  // color: #333333;
  font-size: 24px;
  font-family: HelveticaNeue;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  letter-spacing: 1px;
  // margin: 15px 0;
  // text-align: center;
}

.el-input__inner {
  border: 1px solid rgba(224, 224, 224, 1) !important;
}

/****************************************************************************/

//表格
.app-body {
  .el-table {
    .el-table__footer {
      td:nth-child(1) .cell {
        margin: 0;
        text-align: center;
        padding: 0;
      }
      td .cell {
        color: #333333 !important;
        font-size: 12px !important;
        text-align: center;
        margin: 10px 0;
        font-weight: bold !important;
      }
    }
    td:nth-child(1) .cell {
      margin: 0;
      text-align: center;
      padding: 0;
      line-height: normal !important;
    }
    td {
      padding: 0;
    }
    td .cell {
      color: #333333 !important;
      font-size: 12px !important;
      text-align: center;
      line-height: normal !important;
    }
    th:nth-child(1) {
      .cell {
        text-align: center;
        padding: 0;
        margin: 0;
      }
    }
    th {
      padding: 8px 0;
      background: rgba(245, 245, 245, 1);

      .cell {
        color: #333333 !important;
        font-size: 12px !important;
        text-align: center;
        font-weight: bold !important;
      }
    }
  }
}

.goodabc {
  margin: 0 15px 15px;
  .el-table {
    .el-table__footer {
      td:nth-child(1) .cell {
        margin: 0;
        text-align: center;
        padding: 0;
      }
      td .cell {
        color: #333333 !important;
        font-size: 12px !important;
        text-align: center;
        margin: 10px 0;
        font-weight: bold !important;
      }
    }
    td:nth-child(1) .cell {
      margin: 0;
      text-align: center;
      padding: 0;
    }
    td {
      padding: 0;
    }
    td .cell {
      color: #333333 !important;
      font-size: 12px !important;
      text-align: center;
    }
    th:nth-child(1) {
      .cell {
        text-align: center;
        padding: 0;
        margin: 0;
      }
    }
    th {
      padding: 8px 0;
      background: rgba(245, 245, 245, 1);

      .cell {
        color: #333333 !important;
        font-size: 12px !important;
        text-align: center;
        font-weight: bold !important;
      }
    }
  }
}

.el-table {
  .el-table__footer {
    td:nth-child(1) .cell {
      margin: 0;
      text-align: center;
      padding: 0;
    }
    td .cell {
      color: #333333 !important;
      font-size: 12px !important;
      text-align: center;
      margin: 10px 0;
      font-weight: bold !important;
    }
  }
  td:nth-child(1) .cell {
    margin: 0;
    text-align: center;
    padding: 0;
    line-height: normal !important;
  }
  td {
    padding: 0;
  }
  td .cell {
    color: #333333 !important;
    font-size: 12px !important;
    text-align: center;
    line-height: normal !important;
  }
  th:nth-child(1) {
    .cell {
      text-align: center;
      padding: 0;
      margin: 0;
    }
  }
  th {
    padding: 8px 0;
    background: rgba(245, 245, 245, 1);

    .cell {
      color: #333333 !important;
      font-size: 12px !important;
      text-align: center;
      font-weight: bold !important;
    }
  }
}

.rfms {
  .el-table .success-row {
    background: rgb(245, 106, 110);
  }
}
.collapse {
  margin: 0px 20px 0;
  overflow: hidden;
}
.el-collapse-item__wrap {
  position: relative;
  border-bottom: none;
}
.el-collapse-item__content {
  padding-bottom: 0;
}
.btnquery {
  display: none;
  width: 60px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: #ff6b00;
  color: #fff;
  border-radius: 4px;
  position: fixed;
  right: 154px;
  bottom: 9px;
  left: unset !important;
  top: unset !important;
  z-index: 100000;
}
// .btnquery {
//   position: fixed;
//   top: 30%;
//   right: 40px;
//   width: 60px;
//   height: 60px;
//   border-radius: 50%;
//   line-height: 60px;
//   white-space: nowrap;
//   cursor: pointer;
//   background: rgb(244, 53, 48);
//   border: 1px solid rgb(244, 53, 48);
//   border-color: rgb(244, 53, 48);
//   color: #fff;
//   -webkit-appearance: none;
//   text-align: center;
//   -webkit-box-sizing: border-box;
//   box-sizing: border-box;
//   outline: none;
//   margin: 0 0 15px;
//   -webkit-transition: 0.1s;
//   transition: 0.1s;
//   font-weight: 500;
//   -moz-user-select: none;
//   -webkit-user-select: none;
//   -ms-user-select: none;
//   font-size: 14px;
//   z-index: 10000;
// }

.el-picker-panel__shortcut {
  font-size: 14px;
}
.el-collapse {
  border-top: none;
  border-bottom: none;
}
.left {
  float: left;
}
.right {
  float: right;
}
.el-aside {
  position: fixed;
  top: 93px;
  height: 100%;
  overflow-x: hidden;
  transition: width 0.6s;
}

.el-main {
  margin-left: $asideWidth;
  padding: 0;
  transition: margin-left 0.6s;
}

// .width180px .app-header{
//     width:calc(100% - 230px)
// }
.width65px .app-header {
  width: calc(100% - 115px);
}
.query-options,
.app-body-main {
  border-radius: 5px;
}

.store-inner-tree .el-tree-node__content {
  height: 26px !important;
}
.el-tree-node__content:hover {
  background: #ebefff !important;
  color: #3d4c8f !important;
  border-right: 4px solid #4557bf;
}
.app-body {
  min-height: calc(100% - 64px);

  .app-body-side {
    box-shadow: -7px 0px 19px 0px rgba(0, 0, 0, 0.2);
    position: fixed;
    right: 0;
    width: $bodySideWidth;
    height: calc(100vh - 93px);
    overflow: auto;
    background: #fff;
    border: 1px solid #edebe9;
    z-index: 10;

    .app-body-side-head {
      display: flex;
      align-items: center;
      height: 50px;
      justify-content: center;
      h3 {
        margin-left: 20px;
        color: #333333;
        font-weight: 600;
        font-size: 14px;
        border-left: 4px solid #ff6b00;
        padding: 0 0 0 17px;
        border-radius: 2px;
        margin-right: auto;
      }
      img {
        margin-left: auto;
        margin: 0 10px 0 0;
        width: 18px;
        height: 18px;
      }
    }
    .el-collapse-item__header {
      padding-left: 15px;
      background-color: #fff;
    }
    .el-tree {
      .is-checked {
      }
      .el-tree-node {
        font-size: 14px !important;
        color: #87898c;
      }
    }
  }

  .app-body-main {
    padding: 0 0 16px 0;
    // margin-right: 230px;
    .query-options {
      margin-bottom: $bodySideMargin;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .el-select {
        margin: 6px 0;
      }
      .el-date-editor {
        margin: 6px;
        width: 160px;
      }
      .el-date-editor.el-range-editor {
        width: 260px;
      }
      .options-group {
        width: calc(33.33% - 8px);
        height: 123px;
        padding: 15px;
        background: white;
        font-size: 12px;
        box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        box-sizing: border-box;
        .button_mini {
          padding: 5px 0;
          width: 22%;
        }
        .button_mini_date {
          padding: 5px 0;
          margin-top: 2px;
          width: 45px;
        }
        .switch_W {
          width: 120px;
          height: 28px;
          margin: 6px;
        }
        span {
          color: #3d4b9f;
        }
        button span {
          color: unset;
        }
        .daterange {
          width: 223px;
        }
        .form-item {
          width: 120px;
          max-width: 50%;
        }
        @media screen and (max-width: 992px) {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }

    .data-area {
      background: white;
      min-height: calc(100vh - 217px);
      box-sizing: border-box;
      border-radius: 8px;
      overflow: hidden;
    }
  }
}

.data-area th:first-child {
  // border-left: 1px solid #cfcfcf
}

.app-body-main .el-switch__label * {
  font-size: 12px;
}
.el-select-dropdown__item {
  font-size: 12px;
}
.app-body-side-content .el-tree-node__label {
  font-size: 12px;
}
#analysis .el-table__body-wrapper {
  height: unset !important;
}
#analysis .el-table {
  height: 200px !important;
}

.userlist {
  min-height: 100vh;
  .el-input__inner {
    // border: none;
    // background: #f3f3f3;
    // color: #c0c0c0;
    font-size: 12px;
  }
}

.el-button--mini {
  padding: 4px 8px !important;
  font-weight: normal !important;
}

.tableinp {
  float: left;
  width: 120px;
  margin: 0;
  padding: 0;
  .el-input__inner {
    border: none;
    background: none;
    color: #333333;
    height: 30px;
    line-height: 30px;
    margin: 0;
    padding: 0;
  }
}
.treeinp {
  .el-input__inner {
    border: none;
  }
}

.optiondata {
  .el-date-editor {
    margin: 0;
  }
}

.headtop {
  margin: 86px 20px 0px;
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  h4 {
    font-size: 16px;
    color: #333333;
    font-weight: 500;
    margin-left: 20px;
  }
  .rig {
    margin-left: auto;
    img {
      width: 19px;
      height: 19px;
      margin-right: 40px;
    }
  }
}

.gltablist {
  margin: 12px 0 10px;
  display: flex;
  list-style: none;
  li {
    padding: 0 18px;
    height: 32px;
    text-align: center;
    line-height: 28px;
    font-size: 14px;
    color: #fff;
    background: url('../assets/icon/gltab2.png') no-repeat;
    background-size: 100% 100%;
    margin-right: 10px;
    cursor: pointer;
  }
}
.gltablist_item {
  background: url('../assets/icon/gltab.png') no-repeat !important;
  background-size: 100% 100% !important;
}
.title_top {
  font-size: 18px;
  color: #333333;
  margin: 88px 0 0 30px;
}
.mintitle {
  margin: 21px 0 11px 0;
  h3 {
    margin-left: 20px;
    color: #333333;
    font-weight: 600;
    font-size: 14px;
    border-left: 4px solid #ff6b00;
    padding: 0 0 0 17px;
    border-radius: 2px;
    margin-right: auto;
  }
}

.el-step__title.is-success {
  color: #ff6b00;
}
.el-step__head.is-success {
  color: #ff6b00;
  border-color: #ff6b00;
}

.tablist_top {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 20px 30px 0 20px;
  border-bottom: 1px solid #e4e4e4;

  li {
    height: 33px;
    font-size: 14px;
    color: #333333;
    margin-right: 30px;
    cursor: pointer;
    a {
      color: #333333;
      text-decoration: none;
    }
  }
}
.tablist_top .tablist_topItem {
  color: #ff6b00;
}
.tablist_topItem {
  border-bottom: 2px solid #ff6b00;
}

.screen_right {
  float: right;

  .xz {
    width: 14px;
    height: 13px;
    float: left;
    margin: 7px 23px 0 0;
  }
  ul {
    list-style: none;
    overflow: hidden;
    width: 112px;
    height: 25px;
    border-radius: 5px;
    float: left;

    li {
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 26px;
      margin: 0 23px 0 0;

      img {
        width: 14px;
      }
    }
  }
}

// .screen_right_item {
//   background: #ff6b00;
//   color: #fff;
// }
.emptyprompt {
  color: #909399;
  height: 60px;
  line-height: 60px;
  border: 1px solid #ebeef5;
  margin: 20px 20px 0;
  text-align: center;
}
.container {
  padding: 10px;
  margin: 15px 0;
  background: #fff;
  border-radius: 5px;
}
.chear_cen {
  position: relative;
  min-height: 400px;
  padding: 10px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  // margin: 20px 0 0;
}

.rfm-table {
  .el-table {
    .el-table__header-wrapper .has-gutter tr th {
      background: none;
    }
    .has-gutter {
      .cell {
        height: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .cell {
      text-align: center;
    }
    .el-table__footer-wrapper {
      .has-gutter {
        .cell {
          white-space: pre-line;
        }
      }
    }
  }

  .el-table .el-table__body-wrapper td .cell {
    margin: 17px 0;
  }
  .el-table .el-table__body-wrapper td .cell p {
    padding: 0;
    margin: 5px 0;
  }
  .rfm-cen {
    display: flex;
    justify-content: center;
    div:nth-child(2) {
      line-height: 26px;
    }
    .rfm-left {
      text-align: left;
    }
  }
}

.k_tree {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.k_tree img {
  width: 28px;
  margin: 0 0 0 20px;
  animation: myfirst 1s linear 1s infinite alternate;
  -moz-animation: myfirst 1s linear 1s infinite alternate; /* Firefox */
  -webkit-animation: myfirst 1s linear 1s infinite alternate; /* Safari 和 Chrome */
  -o-animation: myfirst 1s linear 1s infinite alternate;
}

@keyframes myfirst {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes myfirst /* Firefox */ {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes myfirst /* Safari 和 Chrome */ {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes myfirst /* Opera */ {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tablist_no {
  color: #c0c0c0 !important;
}

/**
  客户运营列表样式
 */
.customer-operation {
  // margin-top: 88px;

  .table-outer-container {
    background: white;
    margin-top: 20px;
    padding-bottom: 20px;
    min-height: 350px;
  }

  .box-style {
    margin: 0 15px;
    padding: 20px;
    background: #fff;
    border-radius: 2px;
    border: 1px solid #edebe9;
  }

  //tabs样式
  .tab-content {
    margin: 0 15px;
    background: white;
    border: 1px solid #edebe9;
    border-bottom: 0;
    display: flex;
    font-size: 14px;

    .tab-item,
    .tab-item-active,
    .tab-item-disable {
      list-style: none;
      width: 90px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      position: relative;
    }

    .tab-item-active::after {
      position: absolute;
      content: '';
      display: block;
      width: 60px;
      height: 4px;
      background: #ff6b00;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    .tab-item-disable {
      color: #ccc;
    }
  }
}

//编辑页面
.customer-operation-edit {
  .coupon-form {
    padding: 20px 10px 30px 145px;
    background: #fff;
    border-radius: 2px;
    border: 1px solid #edebe9;
    margin-top: 13px;
    padding-top: 46px;
  }

  .step-content {
    position: relative;
    width: 100%;
    padding-right: 280px;
    box-sizing: border-box;
    color: #333;
  }

  .small-input {
    width: 180px;
    margin-right: 8px;
  }

  .large-input {
    width: 260px;
    word-break: break-all;
  }

  .select-container {
    display: block;
  }

  .large-input-container {
    width: 290px;
    word-break: break-all;
    display: flex;
    align-items: center;
    .large-input-content {
      width: 260px;
      margin-right: 5px;
    }
  }

  .coupon-form .el-form-item {
    width: 100%;
  }

  .step-content /deep/ .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
.ant-table-thead > tr > th,
.el-table thead > tr > th {
  background-color: #f1f2f4 !important;
  color: rgba(0, 0, 0, 0.9) !important;
  font-weight: 500 !important;
}
.ant-table,
.el-table {
  color: rgba(0, 0, 0, 0.8) !important;
}
.ant-tabs {
  color: rgba(0, 0, 0, 0.8);
}
.ant-tabs-nav-container {
  font-size: 16px;
}
.ant-tabs-nav .ant-tabs-tab {
  margin: 0 24px 0 0;
}
.view-area,
.radius-box {
  border-radius: 4px;
}
.ant-card {
  border-radius: 4px;
}

// 智能分析 标题
.search-area > .title,
.page-container > .title {
  padding: 24px 0 20px !important;
  color: rgba(0, 0, 0, 0.9) !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  margin: 0 !important;
}

// 智能订货 圆角
div[class$='-wrap'],
.base-set {
  border-radius: 4px;
}
// 标题
#mz-antd-bread-wrap {
  padding: 14px 0 !important;
  margin: 0 !important;
  #mz-antd-breadcrumb.ant-breadcrumb {
    height: auto !important;
    line-height: normal !important;
  }

  #mz-antd-breadcrumb.ant-breadcrumb a.route-name {
    color: #6b7381;
  }
  #mz-antd-breadcrumb.ant-breadcrumb a.route-name:hover {
    color: #ff8929;
  }
}

.mainContent {
  height: calc(100% - 64px);
  overflow: auto;
}
.menu-data {
  padding-top: 10px;
  // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  // border-radius: 4px;
  // background-color: #fff;
  // border-bottom: 1px solid #d7dae2;
  .el-tabs__header {
    margin: 0 0 10px;
  }
  .el-tabs .el-tabs__item {
    height: 32px;
    line-height: 32px;
    padding: 0px 14px;
  }
  .el-tabs__nav-next,
  .el-tabs__nav-prev {
    height: 32px;
    line-height: 32px;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__item .el-icon-close {
    top: -3px;
  }
  .el-tabs__nav-wrap {
    padding: 0 14px;
  }
  .el-tabs__item:hover {
    color: #ff6b00 !important;
  }
  .el-tabs__item.is-active {
    color: #ff6b00 !important;
  }
}
</style>
